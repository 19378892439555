import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "./src/styles/global.css";
import { pl } from "date-fns/locale";
import { setDefaultOptions } from "date-fns";

setDefaultOptions({ locale: pl });

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lc1s_spAAAAAJAh1vxDDNY6fKgzNdWOO1b-wv8c">
      {element}
    </GoogleReCaptchaProvider>
  );
};
